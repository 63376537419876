import React from "react";
import { motion } from "framer-motion";
import logo from "../assets/images/logo white-01.png";

function LoadingComponent() {
  const scaleVariants = {
    big: { scale: 1.2 },
    small: { scale: 1 },
  };

  return (
    <div className="loader">
      <motion.h1
        initial="small"
        animate="big"
        variants={scaleVariants}
        transition={{
          duration: 0.5,
          yoyo: Infinity,
        }}
        
      >
        <img src={logo} alt='loader'/>
      </motion.h1>
    </div>
  );
}

export default LoadingComponent;
