import Navbar from "./components/navbar/navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AnimatePresence } from "framer-motion";
import Loadable from 'react-loadable';
import LoadingComponent from "./components/loader";
import AOS from 'aos'; 
import {useEffect} from 'react';

function App() {

  useEffect(() => {
    AOS.refresh();
  })

  const Home = Loadable({
    loader: () => import('./pages/home'),
    loading: LoadingComponent,
  });
  
  const Product = Loadable({
    loader: () => import('./pages/product'),
    loading: LoadingComponent,
  });
  
  const AboutUs = Loadable({
    loader: () => import('./pages/aboutUs'),
    loading: LoadingComponent,
  });
  
  return (
    <AnimatePresence>
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<AboutUs />} />
            <Route exact path="/product" element={<Product />} />
          </Routes>
        </div>
      </Router>
    </AnimatePresence>
  );
}

export default App;
